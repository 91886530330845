.modal {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.65);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5em 1em;
  z-index: 999999;
  box-sizing: border-box;
}

.modal.modal-fade {
  animation: fade-in 1s 1 linear;
  animation-fill-mode: forwards;
  opacity: 0;
}

.modal > .modal-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.modal-close {
  background: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 17px;
  margin: 10px 0 5px;
  color: #428688;
  display: inline-block;
  font-weight: 500;
  transition: opacity 0.2s ease-in-out;
}

.modal-close:hover {
  background: rgba(255, 255, 255, 0.989);
  cursor: pointer;
  opacity: 0.9;
}

.modal > .modal-body {
  z-index: 2;
  position: relative;
  margin: 0 auto;
  background-color: rgb(65 134 136);
  border-radius: 8px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100%;
  padding: 20px 30px;
  color: rgb(255 255 255);
  width: 40%;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 30px;
    text-align: center;
  }
}

.modal > .modal-body h3 {
  margin-top: 10px;
  font-size: 22px;
} 


@keyframes fade-in {
  0% {
    animation-timing-function: cubic-bezier(0.2242, 0.7499, 0.3142, 0.8148);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}